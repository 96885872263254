//Стор состояний шаблона

import { nextTick, ref } from 'vue';

import { genGUID } from '@/helpers/utils';
import { mainMenu } from '@/api/system';

export const sidebarHide: any = ref(false);
export const sidebarFullscreen: any = ref(false);
export const sidebarMobile: any = ref(false);
export const navbarMobileShow: any = ref(false);
export const menuItems: any = ref([]);
export const pageTitle: any = ref(null);
export const breadCrumb: any = ref([]);

// breadCrumb.value.push({
// 	route: '/',
// 	icon: 'icon-home2',
// 	caption: 'Домашняя'
// })

// breadCrumb.value.push({
// 	icon: 'icon-file-empty',
// 	caption: 'Dashboard'
// })

export function addBreadCrumb(item: any) {
	breadCrumb.value.push(item);
}

export const fetchMainMenu = async () => {
	menuItems.value = await mainMenu();
}

export const closeSidebar = () => {
	sidebarMobile.value = false;
	sidebarFullscreen.value = false;
	navbarMobileShow.value = false;
}

const activePanels: any = [];

//Массив открытых панелей
export const panels = ref<any>([]);

//ключ открытой панели, если нет открытых панелей или активна основная вкладка то равно null
export const thisPanel = ref(null);
export const thisPanelTab = ref(null);
export const thisPanelTabModal = ref(null);
export const thisPanelModal = ref(null);

//Имя основной вкладки, если пусто то режим работы через модальные панели
export const caption = ref<string>('');

export const setCaption = (value: string) => {
	for (const key in panels.value) {
		if (panels.value[key].modal) return;
	}

	caption.value = value;
}

export const openPanel = (panel: any) => {
	const _panel = {
		modal: false,
		modalTab: false,
		scrollable: false,
		key: genGUID(),
		caption: '',
		component: null,
		center: true,
		buttons: [],
		bodyStyle: {},
		dialogClass: {},
		dialogStyle: {},
		closeable: true,
		topPanel: true,//Кнопки управления сверху
		bottomPanel: false,//Кнопки управления снизу,
		closeButtonCaption: 'Закрыть',
		onShow: () => null,
		onCreate: () => ({}),
		onClose: (result: any = null) => result,
		...panel
	};

	//Если есть уже открыте модальные панели, то новая панель только модально
	for (const key in panels.value) {
		if (panels.value[key].modal) {
			_panel.modal = true;

			break;
		}
	}

	if (!_panel.modal) window.scroll(0, 0);

	for (const key in panels.value) {
		if (panels.value[key].key == _panel.key) {
			activePanel(panels.value[key]);

			return;
		}
	}

	_panel.close = (result: any = true) => result && closePanel(_panel, result);

	if (_panel.center) _panel.dialogClass['modal-dialog-centered'] = _panel.center;

	if (panel.width) _panel.dialogStyle['max-width'] = panel.width;

	if (panel.height) {
		_panel.bodyStyle['height'] = panel.height;
		_panel.bodyStyle['overflow'] = 'scroll';
		_panel.bodyStyle['overflow-x'] = 'hidden';
		_panel.bodyStyle['overflow-y'] = 'auto';
	}

	panels.value.push(_panel);

	const newPanel = panels.value[panels.value.length - 1];

	const initButtons = () => {
		const buttons = newPanel.buttons;

		if (buttons.length > 0) {
			if (!buttons[0].hasOwnProperty('close')) {
				buttons[0].close = {
					caption: 'Закрыть',
					title: 'Закрыть',
					class: 'btn btn-close',
					onClick: () => newPanel.close()
				}
			}
		} else {
			buttons.push({
				close: {
					caption: 'Закрыть',
					title: 'Закрыть',
					class: 'btn btn-close',
					onClick: () => newPanel.close()
				}
			})
		}
	}

	newPanel.setButtons = (buttons: any) => {
		newPanel.buttons.splice(0, newPanel.buttons.length);

		buttons.forEach((button: any) => newPanel.buttons.push(button));

		initButtons();
	}

	Object.assign(newPanel, newPanel.onCreate(newPanel));

	initButtons();

	activePanels.push(newPanel.key);
	setTimeout(() => activePanel(newPanel), 0);

	if (panels.value.filter((el: any) => el.modal).length > 0) {
		//Запретить прокрутку документа
		document.body.style.overflow = 'hidden';
	}

	return newPanel;
}

export const closePanel = async (panel: any, result: any = null) => {
	const _panels = panels.value;

	for (const key in _panels) {
		if (_panels[key].key == panel.key && _panels[key].closeable) {
			const close = () => {
				for (const key in activePanels) {
					if (activePanels[key] == panel.key) {
						activePanels.splice(key, 1);

						break;
					}
				}

				if (activePanels.length > 0) {
					const key = activePanels[activePanels.length - 1];

					for (const i in _panels) {
						if (_panels[i].key == key) {
							nextTick(() => activePanel(_panels[i]));

							break;
						}
					}
				} else {
					nextTick(() => activePanel(null));
				}

				_panels[key].onClose(result);

				_panels.splice(key, 1);
			}

			if (_panels[key].onBeforeClose) {
				if (await _panels[key].onBeforeClose()) close();
			} else {
				close();
			}

			break;
		}
	}

	if (_panels.filter((el: any) => el.modal).length == 0) {
		//Вернуть прокрутку документа если закрыты все модальные окна
		document.body.style.overflowX = 'hidden';
		document.body.style.overflowY = 'inherit';
	}

	setTimeout(() => {
		if (_panels.length == 0) activePanel(null);
	}, 300)
}

export const activePanel = (panel: any) => {
	const _panels = panels.value;

	if (panel) {
		for (const keyA in _panels) {
			if (_panels[keyA].key == panel.key) {
				thisPanel.value = _panels[keyA].key;

				if (_panels[keyA].modal) {
					thisPanelModal.value = _panels[keyA].key;
				} else {
					thisPanelTab.value = _panels[keyA].key;
				}

				for (const keyB in activePanels) {
					if (activePanels[keyB] == panel.key) {
						const _panel = activePanels.splice(keyB, 1);

						activePanels.push(_panel[0])

						break;
					}
				}

				break;
			}
		}
	} else {
		thisPanel.value = null;
		thisPanelTab.value = null;
		thisPanelModal.value = null;
	}
}

export const closeAllPanels = () => {
	panels.value.splice(0, panels.value.length);

	activePanel(null);
}

export const getThisPanel = () => activePanels.length > 0 ? activePanels[activePanels.length - 1] : null;

document.body.addEventListener('keyup', (e: any) => {
	if (e.keyCode == 27 && activePanels.length > 0 && thisPanel.value) {
		if (e.target.localName == "input" && e.target.type == 'file') return;

		e.stopPropagation();

		closePanel({
			key: activePanels[activePanels.length - 1]
		});
	}
})
