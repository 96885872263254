<template>
	<template v-if="isRadioChekBox">
		<component :is="input"></component>
	</template>

	<template v-else-if="raw">
		<div :class="{ 'v-select': isSelect }" :key="controller.field.value">
			<div class="input-group" v-if="triggers.length > 0">
				<component :is="input"></component>
				<Triggers :controller="controller"></Triggers>
			</div>

			<component :is="input" v-else></component>

			<DropDownMenu :controller="controller" v-if="!isText && controller.menuShow.value"></DropDownMenu>

			<DropDown :id="controller.value" :valueField="valueField" :textField="textField" :searchText="searchText" :delay="delay" :controller="controller" @close="onCloseDropDown" @set-data="setData" ref="dropDown"
				v-if="controller.showPopup.value" />
		</div>

		<div :class="{ 'invalid-feedback': !controller.valid.value, 'valid-feedback': controller.valid.value }" v-if="controller.verified.value && controller.feedback.value">{{ controller.feedback.value }}</div>
		<small class="form-text text-muted" v-if="controller.message.value">{{ controller.message.value }}</small>
	</template>

	<div class="form-group" v-else>
		<label :for="controller.labelId" v-if="label" :class="{ 'label-important': important }">
			{{ controller.label }}
			<em class="error help-block" v-if="isRequired">*</em>
		</label>

		<div :class="{ 'v-select': isSelect }" :key="controller.field.value">
			<div class="input-group" v-if="triggers.length > 0">
				<component :is="input"></component>
				<Triggers :controller="controller"></Triggers>
			</div>

			<component :is="input" v-else></component>

			<DropDownMenu :controller="controller" v-if="!isText && controller.menuShow.value"></DropDownMenu>

			<DropDown :id="controller.value" :valueField="valueField" :textField="textField" :searchText="searchText" :delay="delay" :controller="controller" @close="onCloseDropDown" @set-data="setData" ref="dropDown" v-if="controller.showPopup.value" />
		</div>

		<div :class="{ 'invalid-feedback': !controller.valid.value, 'valid-feedback': controller.valid.value }" v-if="controller.verified.value && controller.feedback.value">{{ controller.feedback.value }}</div>
		<small class="form-text text-muted" v-if="controller.message.value">{{ controller.message.value }}</small>
	</div>
</template>

<script lang="jsx">
import { computed, defineComponent, h, onMounted, onUnmounted, ref, watch } from 'vue';
import InputMask from "@/components/InputMask/index.vue";
import DBEditController from './controller';
import Triggers from './Triggers.vue';
import DropDown from './drop-down.vue';
import { genGUID } from '@/helpers/utils';
import DropDownMenu from './drop-down-menu.vue';

export default defineComponent({
	components: {
		DropDown, Triggers, DropDownMenu
	},

	props: {
		form: {
			type: Object,
			default: null
		},
		//ToDo: Если указать массив полей, то тогда выводить несколько полей
		field: {
			type: String,
			default: ''
		},
		label: {
			type: [Boolean, String],
			default: true
		},
		placeholder: {
			type: String,
			default: null
		},
		maxlength: {
			type: Number,
			default: 0
		},
		readonly: {
			type: Boolean,
			default: null
		},
		rows: {
			type: Number,
			default: 0
		},
		min: {
			type: Number,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		precision: {
			type: [Number, String],
			default: null
		},
		validation: {
			type: Boolean,
			default: true
		},
		selectMode: {
			type: Boolean,
			default: false
		},
		multiSelect: {
			type: Boolean,
			default: false
		},
		typeahead: {
			type: Boolean,
			default: false
		},
		searchAway: {
			type: Boolean,
			default: false
		},
		radio: {
			type: Boolean,
			default: false
		},
		valueField: {
			type: String,
			default: 'id'
		},
		textField: {
			type: String,
			default: 'name'
		},
		clear: {
			type: Boolean,
			default: false
		},
		view: {
			type: Boolean,
			default: true
		},
		delay: {
			type: Number,
			default: 250
		},
		type: {
			type: String,
			default: null
		},
		mask: {
			type: String,
			default: null
		},
		template: {
			type: Boolean,
			default: false
		},
		templateLeft: {
			type: Boolean,
			default: false
		},
		focus: {
			type: Boolean,
			default: false
		},
		important: {
			type: Boolean,
			default: false
		},
		completed: {
			type: Boolean,
			default: false
		},
		raw: {
			type: Boolean,
			default: false
		},
		danger: {
			type: Boolean,
			default: false
		},
		style: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props, { emit }) {
		const searchText = ref("");

		const controller = new DBEditController(props, searchText);

		if (!controller.structure) {
			console.error(`DBEdit: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		const onKeydown = async (e) => {
			if (controller.structure?.config?.onKeydown && typeof controller.structure.config.onKeydown == 'function') {
				if (controller.structure.config.onKeydown(e, controller)) return;
			}

			switch (e.keyCode) {
				case 13: {
					if (controller.dropDown.value && controller.dropDown.value.data.rows.length > 0) {
						setData(controller.dropDown.value.data.rows[0]);
					} else if (controller.structure.type == 'TEXT') {
						if (!controller.access || controller.readonly.value) props.form.nextControl(e);
					} else {
						props.form.nextControl(e);
					}

					controller.showPopup.value = false;
				}

					break;

				//F2
				case 113: {
					e.preventDefault();

					if (!props.selectMode && !props.multiSelect) await controller.openReference();
				}

					break;

				//F4
				case 115: {
					e.preventDefault();

					if (!controller.selectMode && !props.multiSelect) await controller.selectReference();
				}

					break;

				//F8
				case 119: {
					e.preventDefault();

					controller.clearValue();
				}

					break;

				//Down
				case 40: {
					if (controller.structure.type.enum) {
						e.preventDefault();

						controller.showPopup.value = true;
					} else if (controller.structure.type.reference && (props.selectMode || props.multiSelect)) {
						e.preventDefault();

						controller.showPopup.value = true;
					}

					if (controller.dropDown.value) {
						e.preventDefault();

						controller.dropDown.value.setFocus();
					}
				}
					break;

				//Insert
				case 45: {
					if (controller.structure.type.reference && !props.selectMode && controller.showPopup.value) {
						await controller.addReference();
					}
				}
					break;

				default:

					break;
			}

		}

		const onInputTypeahead = (e) => {
			searchText.value = e.target.value;

			if (controller.structure.type?.reference) {
				controller.value = null;
				controller.notion = searchText.value;
			} else {
				controller.value = searchText.value;
			}

			if (searchText.value) {
				controller.showPopup.value = true;
			} else {
				controller.showPopup.value = false;
			}
		}

		/**
		 * Создание элемента ввода
		 */
		const input = () => {
			controller.required.value = controller.isRequired() && !controller.value;

			const settings = {
				id: controller.labelId,
				value: controller.value,
				type: 'text',
				readonly: controller.readonly.value,
				autocomplete: 'off',
				placeholder: props.placeholder,
				maxlength: props.maxlength > 0 ? props.maxlength : null,
				ref: controller.el,
				class: {
					'form-control': true,
					'is-invalid': controller.verified.value && !controller.valid.value,
					'is-valid': controller.verified.value && controller.valid.value,
					'border-danger': controller.verified.value && !controller.valid.value,
					'border-success': controller.verified.value && controller.valid.value,
					'border-primary': controller.required.value,
					'important': props.important,
					'completed': props.completed && controller.value,
					'text-danger': props.danger
				},
				style: props.style,
				mask: props.mask ? props.mask : null,
				onKeydown,
				// onInput: () => { },
				// onClick: () => { },
				onBlur: () => controller.onBlur(),
				oncontextmenu: (event) => {
					const { config = {} } = controller.structure;
					if (config.onContextMenu && typeof config.onContextMenu == 'function') {
						config.onContextMenu(event, controller);

						event.preventDefault();
					}
				}
			}

			let component = 'input';

			if (typeof controller.structure.type == 'object') {
				settings.readonly = true;

				if (controller.structure.type.reference) {
					settings.class['reference'] = !controller.readonly.value;

					if (props.selectMode) {
						settings.class['custom-select'] = !controller.readonly.value;
						settings.onClick = () => controller.openPopup();
					} else if (props.multiSelect) {
						settings.class['custom-select'] = !controller.readonly.value;
						settings.onClick = () => controller.openPopup();
					} else if (props.typeahead && !controller.value) {
						settings.readonly = false || controller.readonly.value;
						settings.onInput = onInputTypeahead;
					}

					settings.value = controller.notion;
				} else if (controller.structure.type.enum) {
					if (props.radio) {
						const items = [];

						for (const val of controller.structure.type.enum) {
							const control = h('input', {
								class: 'form-check-input',
								type: 'radio',
								name: controller.field.value,
								id: `${controller.field.value}_${val.id}`,
								value: val.id,
								disabled: !controller.access || controller.readonly.value,
								checked: controller.value == val.id,
								onClick: (e) => {
									controller.value = e.target.value;

									const _enum = controller.structure.type.enum.find(el => el.id == e.target.value);
									if (_enum) controller.notion = _enum.name;
								},
								onBlur: () => controller.verified.value && !controller.valid.value && controller.validation(),
								onKeydown: (e) => {
									switch (e.keyCode) {
										case 13:
											props.form.nextControl(e);

											break;
									}
								}
							});

							items.push(
								h('div', { class: 'form-check form-check-inline' }, [
									control,
									h('label', { class: 'form-check-label', for: `${controller.field.value}_${val.id}` }, val.name)
								])
							)
						}

						return items
					} else {
						settings.class['custom-select'] = !controller.readonly.value;

						settings.value = controller.notion;
						settings.onClick = () => controller.openPopup()
					}

					//eslint-disable-next-line
				} else if (controller.structure.type.sql) {

					//eslint-disable-next-line
				} else if (controller.structure.type.table) {
				} else if (controller.structure.type.fields) {
					settings.class['reference'] = !controller.readonly.value;
				}
			} else if (typeof controller.structure.type === 'string') {
				settings.onInput = (e) => {
					controller.value = e.target.value;

					if (controller.structure.type == 'STRING') {
						if (controller.typeahead && props.typeahead) {
							searchText.value = e.target.value;

							if (searchText.value) {
								controller.showPopup.value = true;
							} else {
								controller.showPopup.value = false;
							}
						}
					} else if (controller.structure.type == 'TEXT' && props.rows > 0) {
						e.target.style['height'] = (e.target.scrollHeight + 2) + 'px';
					}
				}

				if (props.type) settings.type = props.type;

				switch (controller.structure.type) {
					case 'JSON': {
						settings.readonly = true;

						if (props.type == 'text') {
							settings.class['textarea-height'] = true;

							component = 'textarea';
						}
					}
						break;

					case 'UUID': {
						settings.type = 'text';
						settings.mask = 'hhhhhhhh-hhhh-hhhh-hhhh-hhhhhhhhhhhh';
					}

						break;

					case 'DECIMAL':
					case 'SMALLINT':
					case 'INTEGER':
					case 'BIGINT': {
						if (props.min != null) settings['min'] = props.min;
						if (props.max != null) settings['max'] = props.max;

						settings.style['text-align'] = "right";
						settings.type = 'number';
					}

						break;

					case 'DATE': {
						settings.step = 1;
						settings.type = 'datetime-local';
					}
						break;

					case 'DATEONLY': {
						settings.type = 'date';
					}

						break;

					case 'TIME': {
						settings.step = 1;
						settings.type = 'time';
					}
						break;

					case 'BOOLEAN': {
						const id = `id_${genGUID()}`;

						const input = h('input',
							{
								type: 'checkbox',
								id,
								class: 'form-check-input',
								disabled: !controller.access || controller.readonly.value,
								checked: controller.value,
								ref: controller.el,
								onClick: (event) => {
									controller.value = event.target.checked;
									emit('click', event.target.checked);
								},
								onBlur: () => controller.verified.value && !controller.valid.value && controller.validation(),
								onKeydown: (e) => {
									switch (e.keyCode) {
										case 13:
											props.form.nextControl(e);

											break;
									}
								}
							}
						);

						const elements = [];

						elements.push(input);

						elements.push(h('label', { class: 'form-check-label', for: id }, controller.label));

						return elements;
					}

					case 'TEXT': {
						component = 'textarea';

						const template = !!props.template && !controller.readonly.value;

						settings.class['textarea'] = true;
						settings.class['textarea-menu'] = template;

						if (props.rows > 0) {
							settings['rows'] = props.rows;
						}

						if (template) {
							return h(
								'div',
								{
									class: "textarea-field"
								},
								[
									h(component, settings),
									h(
										'div',
										{ class: props.important ? "textarea-btn-bg-important" : "textarea-btn-bg" },
										h('button',
											{
												type: 'button',
												class: {
													btn: true,
													'textarea-btn-menu': true,
													'border-danger': controller.verified.value && !controller.valid.value,
													'border-success': controller.verified.value && controller.valid.value,
													'border-primary': controller.required.value,
												},
												title: 'Шаблоны',
												onClick: async (e) => await controller.onOpenMenuTextarea(e)
											},
											<i class="icon icon-chevron-down"></i>
										)
									),
									h(DropDownMenu, { controller, style: 'top: 23px; left: 15px; width: 500px;', left: props.templateLeft })
								]
							)
						}

					}
						break;

					default:
				}
			}

			if (settings.mask) {
				setTimeout(() => {
					if (controller.el.value) controller.el.value = controller.el.value.$el
				}, 0);

				return h(InputMask, settings);
			} else {
				return h(component, settings);
			}
		}

		const onCloseDropDown = (setFocus = false) => {
			setTimeout(() => {
				controller.showPopup.value = false;

				if (setFocus) controller.setFocusInput();
			}, 0)
		}

		const isRequired = computed(() => controller.isRequired());

		const isRadioChekBox = computed(() => {
			if (typeof controller.structure.type == 'object') {
				if (controller.structure.type.enum && props.radio) {
					return true;
				}
			} else if (typeof controller.structure.type === 'string') {
				if (controller.structure.type == 'BOOLEAN') {
					return true;
				}
			}

			return false;
		})

		const isSelect = computed(
			() => {
				return !(typeof controller.structure.type === 'string' && (controller.structure.type == 'TEXT' || controller.structure.type == 'JSON'))
			}
		);

		const isText = computed(() => (typeof controller.structure.type === 'string' && controller.structure.type == 'TEXT'));

		const validation = () => controller.validation();

		const setData = (data) => {
			if (data) controller.setData(data);

			controller.setFocusInput();
		}

		onMounted(() => {
			if (controller.structure.validation && Object.keys(controller.structure.validation).length)
				props.form.addValidation(validation);

			props.form.setField(controller);

			if (props.focus) controller.setFocusInput();

			controller.updateTextareaHeight();

			watch(() => controller.valueField, () => controller.init());
		})

		onUnmounted(() => {
			if (controller.structure.validation && Object.keys(controller.structure.validation).length)
				props.form.delValidation(validation);

			props.form.deleteField(controller);
		})

		return {
			dropDown: controller.dropDown,
			controller,
			input,
			triggers: computed(() => controller.triggers()),
			menuItems: computed(() => controller.menu.items),
			onCloseDropDown,
			isRequired,
			searchText,
			isRadioChekBox,
			isSelect,
			isText,
			setData
		}
	}
})
</script>

<style>
.v-select {
	position: relative;
	/* width: 100%;
	height: 30px;
	cursor: pointer;
	padding-bottom: 36px; */
}

.v-select>.custom-select {
	transition: all ease-in-out 0s;
}

.v-select.disabled {
	cursor: not-allowed;
}

.v-select.disabled .v-select-toggle {
	background-color: #f8f9fa;
	border-color: #f8f9fa;
	opacity: 0.65;
	cursor: not-allowed;
}

.v-select.disabled .v-select-toggle:focus {
	outline: 0 !important;
}

.v-select-toggle {
	outline-style: none;
	display: flex;
	justify-content: space-between;
	user-select: none;
	padding: 0.31rem 0.75rem;
	color: #212529;
	background-color: #fff;
	border-color: #d3d9df;
	width: 100%;
	text-align: right;
	white-space: nowrap;
	font-size: 12px;
	line-height: 2;
	border-radius: 0.25rem;
	transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
	cursor: pointer;
	border: 1px solid #ddd;
}

.v-select-toggle:hover {
	background-color: #e2e6ea;
	border-color: #dae0e5;
}

.arrow-down {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	margin-top: 10px;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.invalid-feedback {
	display: block !important;
	font-size: 90% !important;
	margin-top: 0.1rem !important;
}

.valid-feedback {
	display: block !important;
	font-size: 90% !important;
	margin-top: 0.1rem !important;
}

.feedback {
	display: block;
	font-size: 90%;
	margin-top: 0.1rem;
	margin-bottom: 0.4rem;
	color: #888;
}

.form-text {
	margin-top: 0.15rem;
}

.custom-select:focus {
	box-shadow: 0 0 0 1px rgb(184, 184, 184);
}

input>.custom-select:disabled,
.custom-select[readonly] {
	background-color: #fff;
}

.textarea-field {
	width: 100%;
	position: relative;
}

.textarea-btn-menu {
	position: absolute;
	left: 4px;
	top: 5px;
	font-size: 10px;
	margin: 0px;
	padding: 0px 5px 0px 5px;
	background: #fafafa;
	border: 1px solid #ddd;
	color: #000;
}

.textarea-btn-bg {
	position: absolute;
	left: 0px;
	top: 0px;
	background: #f8f8f8;
	height: calc(100% - 2px);
	width: 25px;
	margin: 1px;
	border-radius: 2px 0px 0px 2px;
}

.textarea-btn-bg-important {
	position: absolute;
	left: 0px;
	top: 0px;
	background: #f8f8f8;
	height: calc(100% - 4px);
	width: 25px;
	margin: 2px;
	border-radius: 2px 0px 0px 2px;
}

.textarea {
	min-height: 33px;
}

.textarea-menu {
	padding-left: 28px;
	min-height: 33px;
	height: 100%;
	overflow-y: hidden;
}

.completed {
	background-color: #9ae5de !important;
}

.important {
	border: 2px solid #26a69a;
}

.label-important {
	color: #000;
	font-weight: 500;
}
</style>
