import country from "./country";
import contacts from "./contacts";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import brigades from "./brigades";
import brigades_list from "./brigades_list";
import call_log from "./call_log";
import employees from "./employees";
import files from "./files";
import files_versions from "./files_versions";
import patients from "./patients";
import templates from "./templates";
import icd10 from "./icd10";

import { deepClone, deepMerge } from "@/helpers/utils";

const tables: any = {
	country,
	brigades,
	contacts,
	contragents,
	'contragents.departments': contragents_departments,
	brigades_list,
	call_log,
	employees,
	templates,
	files,
	'files.versions': files_versions,
	icd10,
	patients
}

export const schema: any = {};

export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		schema[key] = deepMerge(dbSchema[key], tables[key] ? tables[key] : {});
	}

	// console.log(schema);
}

export const getModel = (name: string) => {
	const model = schema[name];

	if (model) {
		const _model = deepClone(model);

		//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
		if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

		return _model;
	} else {
		return tables[name] ? deepMerge({ offLine: true }, tables[name]) : { offLine: true };
	}
}
